<template>
  <v-row no-gutters>
    <v-col>
      <div id="data-list">
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-xl'"
          :disabled="loading"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? '#28243d' : 'transparent'"
            :flat="!$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiAsterisk }}
              </v-icon>
              Cari Risk
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <download-excel :data="json_data" :fields="json_fields" name="CARI_RISK__EKSUN.xls">
              <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
            </download-excel>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            :headers="tableColumns"
            :items="tableItems"
            :items-per-page="-1"
            :mobile-breakpoint="300"
            fixed-header
            height="calc(100dvh - 236px)"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
            }"
          >
            <template #[`item.CUSTOMER`]="{ item }">
              <v-chip
                color="chip"
                class="d-flex justify-center text-overline"
                style="font-size: 11px !important; color: azure"
                label
              >
                {{ item.CUSTOMER }}
              </v-chip>
            </template>

            <template #[`item.NAME1`]="{ item }">
              <span class="d-inline-block text-truncate" style="max-width: 400px">
                {{ item.NAME1 }}
              </span>
            </template>

            <template #[`item.BORC`]="{ item }">
              {{ item.BORC | currency }}
            </template>

            <template #[`item.ALACAK`]="{ item }">
              {{ item.ALACAK | currency }}
            </template>

            <template #[`item.TEMINAT`]="{ item }">
              {{ item.TEMINAT | currency }}
            </template>

            <template #[`item.CREDITLIMIT`]="{ item }">
              {{ item.CREDITLIMIT | currency }}
            </template>

            <template #[`item.AVAIL`]="{ item }">
              {{ item.AVAIL | currency }}
            </template>

            <template #[`item.RISK`]="{ item }">
              {{ item.RISK | currency }}
            </template>

            <template #[`item.CEK`]="{ item }">
              {{ item.CEK | currency }}
            </template>

            <template #[`item.SENET`]="{ item }">
              {{ item.SENET | currency }}
            </template>

            <template #[`item.SIPARIS`]="{ item }">
              {{ item.SIPARIS | currency }}
            </template>

            <template #[`item.IRSALIYE`]="{ item }">
              {{ item.IRSALIYE | currency }}
            </template>

            <template #[`item.FATURA`]="{ item }">
              {{ item.FATURA | currency }}
            </template>

            <template #[`item.BAKIYE`]="{ item }">
              {{ item.BAKIYE | currency }}
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import { postData, querySearch, formatMoney } from '@utils'
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiDownload, mdiFilter, mdiSend, mdiAsterisk } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  components: {
    CustomerList,
  },
  setup() {
    const { isDark } = useAppConfig()

    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(true)
    const validfrom = ref(
      new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
    )
    const validuntil = ref(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const startMenu = ref(false)
    const endMenu = ref(false)
    const tableItems = ref([])
    const tableFooter = ref([])

    const totalDataListTable = ref(0)

    const tableColumns = [
      {
        text: 'Cari Kodu',
        value: 'CUSTOMER',
        sortable: false,
        width: 150,
      },
      {
        text: 'Cari Adı',
        value: 'NAME1',
        sortable: false,
        align: 'left min-width-400',
      },

      {
        text: 'Borç Bakiyesi',
        value: 'BORC',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Alacak Bakiyesi',
        value: 'ALACAK',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Teminat Mek. Toplam',
        value: 'TEMINAT',
        sortable: false,
        width: '200px',
        align: 'right',
      },
      {
        text: 'Kredi Limiti',
        value: 'CREDITLIMIT',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Kullanılabilir Limit',
        value: 'AVAIL',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Toplam Risk',
        value: 'RISK',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Çek Toplam',
        value: 'CEK',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Senet Toplam',
        value: 'SENET',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Açık Sipariş',
        value: 'SIPARIS',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Açık İrsaliye',
        value: 'IRSALIYE',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Faturalar',
        value: 'FATURA',
        sortable: false,
        width: '150px',
        align: 'right',
      },

      {
        text: 'Bakiye',
        value: 'BAKIYE',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Fatura Ağırlık Ortalaması',
        value: 'AODATE',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Ödeme Ağırlık Ortalaması',
        value: 'ODEMEORT',
        sortable: false,
        width: '150px',
        align: 'right',
      },
      {
        text: 'Bloke Durumu',
        value: 'STATUTXT',
        sortable: false,
        width: '150px',
        align: 'right',
      },
    ]
    const loading = ref(false)

    const json_data = ref([])

    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
      }
      fetchBalances()
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        status.value = true
        loading.value = true
        tableItems.value = []
        fetchBalances()
      },
    )

    const fetchBalances = () => {
      loading.value = true

      postData(
        querySearch(
          {
            customer: store.state.plasiyerCustomer || null,
          },
          'getFINT12ReportWS',
        ),
      )
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error === 1) {
            throw new Error(response.msg)
          }
          status.value = true
          tableItems.value = response.detail
          json_data.value = response.detail
        })
        .catch(e => {
          // API hataya girdi
        })
        .finally(() => {
          loading.value = false
          // API işlemi bittikten sonra
        })
    }

    return {
      isDark,
      tableColumns,
      tableItems,
      json_data,
      tableFooter,
      loading,
      validfrom,
      validuntil,
      startMenu,
      endMenu,
      status,
      userData,
      fetchBalances,
      totalDataListTable,

      // icons
      icons: {
        mdiDownload,
        mdiAsterisk,
        mdiFilter,
        mdiSend,
      },
    }
  },
  data() {
    return {
      dataTableHeight: '',
      height: {
        system: 0,
        top: 95,
        footer: 51,
      },
      json_fields: {
        'Cari Kodu': 'CUSTOMER',
        'Cari Adı': 'NAME1',
        'Borç Bakiyesi': 'BORC',
        'Alacak Bakiyesi': 'ALACAK',
        'Teminat Mek. Toplam': 'TEMINAT',
        'Kredi Limiti': 'CREDITLIMIT',
        'Kullanılabilir Limit': 'AVAIL',
        'Toplam Risk': 'RISK',
        'Çek Toplam': 'CEK',
        'Senet Toplam': 'SENET',
        'Açık Sipariş': 'SIPARIS',
        'Açık İrsaliye': 'IRSALIYE',
        Faturalar: 'FATURA',
        'Fatura Ağrılık Ortalaması': 'AODATE',
        'Ödeme Ağırlık Ortalaması': 'ODEMEORT',
        Bakiye: 'BAKIYE',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 60 - this.height.top - this.height.footer - 1
    },
  },
}
</script>
