var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"data-list"}},[(_vm.userData.role === 'PLSYR' && _vm.$vuetify.breakpoint.xsOnly)?_c('customer-list'):_vm._e(),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-xl',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown,"disabled":_vm.loading}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? '#28243d' : 'transparent',"flat":!_vm.$vuetify.breakpoint.smAndDown,"height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAsterisk)+" ")]),_vm._v(" Cari Risk ")],1),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"name":"CARI_RISK__EKSUN.xls"}},[_c('v-btn',{attrs:{"plain":"","text":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v(" İndir ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.tableItems,"items-per-page":-1,"mobile-breakpoint":300,"fixed-header":"","height":"calc(100dvh - 236px)","loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
          }},scopedSlots:_vm._u([{key:"item.CUSTOMER",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"d-flex justify-center text-overline",staticStyle:{"font-size":"11px !important","color":"azure"},attrs:{"color":"chip","label":""}},[_vm._v(" "+_vm._s(item.CUSTOMER)+" ")])]}},{key:"item.NAME1",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"400px"}},[_vm._v(" "+_vm._s(item.NAME1)+" ")])]}},{key:"item.BORC",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.BORC))+" ")]}},{key:"item.ALACAK",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.ALACAK))+" ")]}},{key:"item.TEMINAT",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.TEMINAT))+" ")]}},{key:"item.CREDITLIMIT",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.CREDITLIMIT))+" ")]}},{key:"item.AVAIL",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.AVAIL))+" ")]}},{key:"item.RISK",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.RISK))+" ")]}},{key:"item.CEK",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.CEK))+" ")]}},{key:"item.SENET",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.SENET))+" ")]}},{key:"item.SIPARIS",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.SIPARIS))+" ")]}},{key:"item.IRSALIYE",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.IRSALIYE))+" ")]}},{key:"item.FATURA",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.FATURA))+" ")]}},{key:"item.BAKIYE",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.BAKIYE))+" ")]}}],null,true)})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }